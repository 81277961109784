//
// Check/radio
//
.form-check {
	margin-bottom: 0.525rem;
}

.form-check-input {
	@include dark-mode {
		background-color: $dark-form-check-input-bg;

		&:not([class*='valid']) {
			border-color: $dark-form-check-input-border-color;
		}
	}

	&:checked {
		@include dark-mode {
			background-color: $form-check-input-checked-bg-color;
			border-color: $form-check-input-checked-border-color;
		}

		&[type='checkbox'] {
			@include dark-mode {
				@if $enable-gradients {
					background-image: escape-svg($form-check-input-checked-bg-image),
						var(--#{$prefix}gradient);
				} @else {
					background-image: escape-svg($form-check-input-checked-bg-image);
				}
			}
		}

		&[type='radio'] {
			@include dark-mode {
				@if $enable-gradients {
					background-image: escape-svg($form-check-radio-checked-bg-image),
						var(--#{$prefix}gradient);
				} @else {
					background-image: escape-svg($form-check-radio-checked-bg-image);
				}
			}
		}
	}

	&[type='checkbox']:indeterminate {
		background-color: $form-check-input-indeterminate-bg-color;
		border-color: $form-check-input-indeterminate-border-color;

		@if $enable-gradients {
			background-image: escape-svg($form-check-input-indeterminate-bg-image),
				var(--#{$prefix}gradient);
		} @else {
			background-image: escape-svg($form-check-input-indeterminate-bg-image);
		}
	}
}
